.banner-image { 
    display: 'block';
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
    z-index: -1;
}

.banner-text .h1 { 
    font-size: 3rem;
}