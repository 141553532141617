:root {
    --white: #ffffff;
    --ghost-white: #f6f6ff;
    --lily-white: #e2f2e9;
    --black: #000000;
    --grey: #878787;
    --silver: #c2c2c2;
    --white-grey: #ececec;
    --alabaster-white: #f9f9f9;
    --dim-grey: #707070;
    --dimmer-grey:#575757;
    --blue: #1a56fa;
    --light-blue: #4274fd;
    --light-blue-pulse: #28acdc;
    --royal-blue: #4361ee;
    --medium-dark-blue: #3a0ca3;
    --dark-blue: #010548;
    --purple: #7209b7;
    --eggplant-purple: #580b8b;
    --dark-purple: #450570;
    --haze-green: #00b147;
    --dark-green: #008535;
    --lime-green: #1bdc77;
    --green-cyan: #d7f8e7;
    --red: #f00a21;
    --rose-red: #ffe0dc;
    --gold: #fdf0d1;
    --orange: #f88c0c;
    --light-orange: #f9e7d2;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: 'Peugeot';
        src: url(./fonts/Peugeot.ttf);
    }
    @font-face {
        font-family: 'Citroen';
        src: url(./fonts/Citroen.ttf);
    }
    @font-face { 
        font-family: 'HyundaiBusiness';
        src: url(./fonts/HyundaiBusiness.ttf);
    }
    @font-face {
        font-family: 'Hyundai-Text';
        src: url(./fonts/Hyundai/HyundaiSansText-Regular.otf)
    }
}

@layer components {
    .btn {
        @apply transition-all py-2 px-6 text-base rounded-md;
    }

    .btn-icon {
        @apply flex flex-row items-center transition-all p-2 text-white text-base rounded border;
    }

    .btn-next-state {
        @apply border-purple-standard bg-purple-standard hover:bg-purple-eggplant hover:border-purple-eggplant;
    }

    .btn-complete-submission {
        @apply border-green-haze bg-green-haze hover:bg-green-dark hover:border-green-dark;
    }

    .btn-primary {
        @apply text-white border-blue-light border bg-blue-light hover:bg-blue-standard hover:border-blue-standard;
    }

    .btn-primary-small {
        @apply text-blue-standard hover:text-blue-dark text-sm border-transparent;
    }

    .btn-primary-xs {
        @apply text-blue-standard hover:text-blue-dark text-sm border-transparent;
    }

    .btn-secondary {
        @apply text-blue-light border-blue-light border bg-white hover:bg-blue-standard hover:border-blue-standard hover:text-white;
    }

    .input-border {
        @apply border border-gray-300 rounded p-3 h-10 outline-none focus:ring focus:border-blue-light focus-visible:border-blue-light active:border-blue-royal;

        &[data-error='true'] {
            @apply border-red-standard;
        }
    }
}
